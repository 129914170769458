import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import Link from "lib/Link";

@withI18next(["common"])
@inject("appStore", "readerStore")
@observer
class FeatureBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() { }

  render() {
    let { t, appStore, readerStore, search } = this.props;
    let { indexdata } = appStore;
    if (indexdata === null || indexdata === undefined) {
      return null;
    }
    let { featureBlock } = indexdata;
    if (featureBlock === null || featureBlock === undefined) {
      return null;
    }
    let { domainList } = featureBlock;
    let { session } = readerStore;
    let params = { ...search };
    let { domain = "" } = params;
    domain = domain !== "" ? domain : "default";
    let featureDomain = {
      domain: "default",
      mainFeature: null,
      showType: 1,
    };
    if (domainList) {
      domainList.map((m) => {
        if (m.domain === domain) {
          featureDomain = m;
        }
      })
    }
    let { mainFeature = [] } = featureDomain;
    if (!mainFeature || mainFeature.length === 0) {
      return null;
    }
    let featureShowType = featureDomain.showType
    if (!featureShowType || featureShowType === 0) {
      featureShowType = 1;
    }
    if (featureShowType === 1) {
      return (
        <>
          <section className="col-4-4-4 feature_block">
            <div className="container">
              {mainFeature.map((columnData, _) => {
                let moreUrl = "";
                let title = "";
                let titleClass = "";
                let mainLangcode = "";             
                let out = [];
                columnData.mappingData &&
                columnData.mappingData.map((row, _) => {
                  if (
                    row.data &&
                    row.condition ===
                    session.groupIDVal +
                    "-" +
                    session.useUnitGroupSN
                  ) {
                    title = row.title;
                    titleClass = row.titleClass || "";
                    mainLangcode = row.mainLangcode;
                    moreUrl = row.moreUrl;      
                    row.data.map((row2, key) => {
                      out.push(
                        <li className={key % 2 === 0 ? "" : "tdbg"}>
                          <Link href={row2.url}>{row2.name}</Link>
                        </li>
                      );
                    });
                  }
                  return out;
                })
                let titleName =
                  mainLangcode === ""
                    ? title
                    : t(mainLangcode);
                if (out.length > 0) {
                  return (
                    <div className="col">
                      <div className="featured_data">
                        <h2 className={titleClass}>{titleName}</h2>
                        <ul>
                          {out}
                        </ul>
                        {moreUrl !== "" && (
                          <Link href={moreUrl} className="more">
                            {t("jumperrwd.featureblock.more", {
                              moreName: titleName,
                            })}
                          </Link>
                        )}
                      </div>
                    </div>
                  );
                }
                return "";
              })}
            </div>
          </section>
        </>
      );
    } else if (featureShowType === 2) {
      return (
        <>
          <section className="col-4-4-4 feature_block">
            <div className="container">
              {mainFeature.map((columnData, _) => {
                let moreUrl = "";
                let title = "";
                let titleClass = "";
                let mainLangcode = "";             
                let out = [];
                columnData.mappingData &&
                columnData.mappingData.map((row, _) => {
                  if (
                    row.data &&
                    row.condition ===
                    session.groupIDVal +
                    "-" +
                    session.useUnitGroupSN
                  ) {
                    title = row.title;
                    titleClass = row.titleClass || "";
                    mainLangcode = row.mainLangcode;
                    moreUrl = row.moreUrl;      
                    row.data.map((row2, key) => {
                      out.push(
                        <li className={key % 2 === 0 ? "" : "tdbg"}>
                          <Link href={row2.url}>{row2.name}</Link>
                        </li>
                      );
                    });
                  }
                  return out;
                })
                let titleName =
                  mainLangcode === ""
                    ? title
                    : t(mainLangcode);
                if (out.length > 0) {
                  return (
                    <div className="col">
                      <div className="featured_data">
                        <h2 className={titleClass}>
                          {titleName}
                          {moreUrl !== "" && (
                            <span className="more">
                              <Link href={moreUrl} className="btna">
                                {t("jumperrwd.featureblock.more", {
                                  moreName: titleName,
                                })}
                              </Link>
                            </span>
                          )}
                        </h2>
                        <ul>{out}</ul>
                      </div>
                    </div>
                  );
                }
                return "";
              })}
            </div>
          </section>
        </>
      );
    }
  }
}

FeatureBlock.defaultProps = {};

FeatureBlock.propTypes = {
  t: PropTypes.func,
};
export default FeatureBlock;
