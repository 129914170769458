import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import Slider from "react-slick";
import Link from "lib/Link";
import withLocation from "lib/withLocation";
import "static/vendor/slick/slick.css";
import "static/vendor/slick/slick-theme.css";

@withLocation
@withI18next(["common"])
@inject("appStore", "readerStore")
@observer
class RelatedBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() { }

    render() {
        let { t, appStore, readerStore, search } = this.props;
        let { indexdata } = appStore;
        if (indexdata === null || indexdata === undefined) {
            return null;
        }
        let { relatedBlock } = indexdata;
        if (relatedBlock === null || relatedBlock === undefined) {
            return null;
        }
        let { domainList } = relatedBlock;
        let { session } = readerStore;
        let params = { ...search };
        let { domain = "" } = params;
        domain = domain !== "" ? domain : "default";
        let relatedDomain = {
            domain: "default",
            mappingData: null
        };
        if (domainList) {
            domainList.map((m) => {
                if (m.domain === domain) {
                    relatedDomain = m;
                }
            })
        }
        if (!relatedDomain || !relatedDomain.mappingData) {
            return null;
        }
        let slideOut = [];
        let slidesToShow = relatedDomain.slidesToShow;
        let slidesToScroll = relatedDomain.slidesToScroll;
        relatedDomain.mappingData.map((row) => {
            if (
                row.data &&
                row.condition ===
                session.groupIDVal +
                "-" +
                session.useUnitGroupSN
            ) {
                row.data.map((row2) => {     
                    slideOut.push(
                        <li>
                            <Link href={row2.url} tabindex="-1"><span className={row2.imageClass}><img src={row2.imagePath} /></span>{t(row2.langcode)}</Link>
                        </li>
                    );
                });
            }
        });
        if (slidesToShow > slideOut.length) {
            slidesToShow = slideOut.length;
        }
        if (slidesToScroll > slideOut.length) {
            slidesToScroll = slideOut.length - 1;
        }
        return (
            <>
                <section className="section col-12 related_block">
                    <div className="container">
                        <div className="col">
                            <Slider
                                className="related_links"
                                dots={false}
                                infinite={true}
                                speed={500}
                                slidesToShow={slidesToShow}
                                slidesToScroll={slidesToScroll}
                                draggable={true}
                                responsive={[
                                    {
                                      breakpoint: 1024,
                                      settings: {
                                        slidesToShow: slidesToShow/2 ,
                                        slidesToScroll: slidesToScroll/2,
                                        dots:false,
                                        infinite:true
                                      }
                                    },
                                    {
                                      breakpoint: 600,
                                      settings: {
                                        slidesToShow: slidesToShow/3,
                                        slidesToScroll: slidesToScroll/3,
                                        dots:false,
                                        infinite:true
                                      }
                                    }
                                  ]}
                            >
                                {slideOut}
                            </Slider>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

RelatedBlock.defaultProps = {};

RelatedBlock.propTypes = {
    t: PropTypes.func,
};

export default RelatedBlock;
