import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import Link from "lib/Link";
import withLocation from "lib/withLocation";

@withLocation
@withI18next(["common"])
@inject("appStore", "readerStore")
@observer
class BookListBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() { }

    render() {
        let { t, appStore, readerStore, search } = this.props;
        let { indexdata } = appStore;
        if (indexdata === null || indexdata === undefined) {
            return null;
        }
        let { bookListBlock } = indexdata;
        if (bookListBlock === null || bookListBlock === undefined) {
            return null;
        }
        let { domainList } = bookListBlock;
        let { session } = readerStore;
        let params = { ...search };
        let { domain = "" } = params;
        domain = domain !== "" ? domain : "default";
        let bookListDomain = {
            domain: "default",
            mainBookList: null
        };
        if (domainList) {
            domainList.map((m) => {
                if (m.domain === domain) {
                    bookListDomain = m;
                }
            })
        }
        let { mainBookList = [] } = bookListDomain;
        if (!mainBookList || mainBookList.length === 0) {
            return null;
        }
        return (
            <>
                <div className="booklist_block">
                    <form>
                        <div className="hotqa_block">
                            {mainBookList.map((main) => {
                                let out = [];
                                let mainLangcode = main.mainLangcode ? main.mainLangcode : "";
                                let moreUrl = "";
                                 {main.mappingData && main.mappingData.map((row) => {         
                                    if (
                                        row.data &&
                                        row.condition ===
                                        session.groupIDVal +
                                        "-" +
                                        session.useUnitGroupSN
                                    ) {
                                        moreUrl = row.moreUrl;
                                        mainLangcode = row.mainLangcode;
                                        row.data.map((row2) => {
                                            out.push(
                                                <li>
                                                    <Link href={row2.url}>{t(row2.langcode) + " (" + row2.count + ")"}</Link>
                                                </li>
                                            );
                                        });
                                    }
                                    return out;
                                })}
                                if (mainLangcode !== "") {
                                    return (
                                        <div className={main.cssClass}>
                                            <h2>{t(mainLangcode)}{moreUrl !== "" && <span className="more"><Link href={moreUrl} className="btna">{t("jumperrwd.bookListBlock.more")}</Link></span>}</h2>
                                            {out.length > 0 && (
                                                <ul>
                                                    {out}
                                                </ul>
                                            )}
                                            {main.linkImage && (
                                                <Link href={main.linkImage.url}><img className="childimg" src={main.linkImage.imagePath} onError={this.loadDefaultImg} alt="" /></Link>
                                            )}
                                        </div>
                                    )
                                }
                                return "";
                            })}
                        </div>
                    </form>
                </div>
            </>
        );
    }
}

BookListBlock.defaultProps = {};

BookListBlock.propTypes = {
    t: PropTypes.func,
};

export default BookListBlock;
