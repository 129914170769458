import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import Link from "lib/Link";
import withLocation from "lib/withLocation";
import { autobind } from "react-decoration";

@withLocation
@withI18next(["common"])
@inject("appStore", "readerStore")
@observer
class HotEBooksBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  @autobind
  loadDefaultImg(e) {
    e.target.src = "/file/images/bookcover.jpg";
  }

  render() {
    let { t, appStore, readerStore, search } = this.props;
    let { indexdata } = appStore;
    if (indexdata === null || indexdata === undefined) {
      return null;
    }
    let { hotEBooksBlock } = indexdata;
    if (hotEBooksBlock === null || hotEBooksBlock === undefined) {
      return null;
    }
    let { domainList } = hotEBooksBlock;
    let { session } = readerStore;
    let params = { ...search };
    let { domain = "" } = params;
    domain = domain !== "" ? domain : "default";
    let hotEBooksDomain = {
      domain: "default",
      mappingData: null,
      showType: 1,
    };
    if (domainList) {
      domainList.map((m) => {
        if (m.domain === domain) {
          hotEBooksDomain = m;
        }
      });
    }
    if (!hotEBooksDomain || !hotEBooksDomain.mappingData) {
      return null;
    }
    let moreUrl = "";
    let outList = [];
    hotEBooksDomain.mappingData.map((row) => {
      if (
        row.data &&
        row.condition === session.groupIDVal + "-" + session.useUnitGroupSN
      ) {
        moreUrl = row.moreUrl;
        row.data.map((row2, k) => {
          outList.push(
            <div className="booklist_hot">
              <div className="clickblock">
                <div className="ranking">{"#" + (k + 1)}</div>
              </div>
              <div className="bookleftblock">
                <div className="book">
                  <div className="image">
                    <img src={row2.imagePath} onError={this.loadDefaultImg} />
                  </div>
                </div>
              </div>
              <div className="bookmidblock">
                <div className="bookdata">
                  <h2>
                    <Link href={row2.url} id="linkdetailpage">
                      {t(row2.name)}
                    </Link>
                  </h2>
                </div>
              </div>
            </div>
          );
        });
      }
    });
    let hotEBooksShowType = hotEBooksDomain.showType
    if (!hotEBooksShowType || hotEBooksShowType === 0) {
        hotEBooksShowType = 1;
    }
    if (hotEBooksShowType === 1) {
      return (
        <>
          <section className="hotebooks_block">
            <h2>{t(hotEBooksDomain.mainLangcode)}</h2>
            <div className="booklist_block">
              <form>
                <div className="booklist_hot_block">{outList}</div>
              </form>
            </div>
            {moreUrl !== "" && (
              <div className="more">
                <Link href={moreUrl} className="btnstyle brownbg">
                  {t("jumperrwd.hotEBooksBlock.moreData")}
                </Link>
              </div>
            )}
          </section>
        </>
      );
    } else if (hotEBooksShowType === 2) {
      return (
        <>
          <section className="section col-12 newbooks_block">
            <div className="container">
              <div className="col">
                <h2>{t(hotEBooksDomain.mainLangcode)}</h2>
                {moreUrl !== "" && (
                  <div className="more">
                    <Link href={moreUrl} className="btnstyle brownbg">
                      {t("jumperrwd.hotEBooksBlock.moreData")}
                    </Link>
                  </div>
                )}
                <div className="booklist_block">
                  <form>
                    <div className="booklist_hot_block">{outList}</div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </>
      );
    } else {
      return null;
    }
  }
}

HotEBooksBlock.defaultProps = {};

HotEBooksBlock.propTypes = {
  t: PropTypes.func,
};

export default HotEBooksBlock;
